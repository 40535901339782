import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {faGlobe} from '@fortawesome/free-solid-svg-icons/faGlobe';

@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LangSwitcherComponent implements OnInit {
  languages = [
    {code: 'en', name: 'English'},
    {code: 'de', name: 'Deutsch'},
  ];
  selectedLanguage = this.languages[0];
  protected readonly faGlobe = faGlobe;

  ngOnInit(): void {
    // const savedLanguage = localStorage.getItem('lang');
    const languageCodeInUrl = window.location.pathname.split('/')[1];

    // if (savedLanguage && languageCodeInUrl !== savedLanguage) {
    //   window.location.pathname = savedLanguage + '/';
    // }
    this.selectedLanguage = this.languages.find((item) => item.code === languageCodeInUrl) || this.languages[0];
  }

  changeLanguage(): void {
    const languageCode = this.selectedLanguage.code;
    localStorage.setItem('lang', languageCode);
    const currentLanguageCode = window.location.pathname.split('/')[1];
    if (!['de', 'en'].includes(currentLanguageCode)) {
      throw new Error('Url does not contain language code or it is invalid');
    }
    window.location.pathname = window.location.pathname.replace(currentLanguageCode, languageCode);
    console.log(window.location.pathname);
  }
}
